
  function open_dialog(id) {
  	$(id).dialog();
  }

  function open_dialog_cgu() {
    $( "#sve_cgu" ).dialog({
      buttons: {
        OK: function() {$(this).dialog("close");}
      },
      position: {
        my: "center",
        at: "center"
      },
      width: '80%',
      height: 480
    });
  }
