var Agenda = (function () {
  var onBeforeInit = function (callback, defaultDatas) {
    $.ajax(Routing.generate('csa_get_monthly_events'))
      .done(function(data){
        callback(data);
      });
  };

  var onUpdate = function(callback, date) {
    $.ajax(Routing.generate('csa_get_monthly_events', {date: date}))
      .done(function(data){
        $('.Agenda-calendar').empty();
        callback(data);
      });
  };

  return {
    onBeforeInit: onBeforeInit,
    onUpdate: onUpdate
  };
})();