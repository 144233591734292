;(function newsletter($) {
  $(document).ready(init);

  function init() {
    $('#form_register_newsletter').on('submit', function(e){
      e.preventDefault();

      $.ajax({
        type: $(this).attr('method'),
        url: $(this).attr('action'),
        data: $(this).serialize()
      }).done(function (data) {
        var $div = $('.confirmNewsletter');
        $div.text(data);
        $div.fadeIn();
        // setTimeout(function() {
        //   $div.fadeOut('slow');
        //   $div.text('');
        // },5000);
      })
    })
  }
}(jQuery));

