jQuery(document).ready(function($) {
	$( "input[type=checkbox][name='service_1']" ).click(function(){

	    if($('#service_1').is(':checked')){$( ".divservice_1_description" ).show(); $(".erroraumoinscases_cochee").css({"visibility":"hidden"});}
	    if(!$('#service_1').is(':checked')){$( ".divservice_1_description" ).hide();}
	})
	$( "input[type=checkbox][name='service_2']" ).click(function(){
	     if($('#service_2').is(':checked')){$( ".divservice_2_description" ).show(); $(".erroraumoinscases_cochee").css({"visibility":"hidden"});}
	     if(!$('#service_2').is(':checked')){$( ".divservice_2_description" ).hide();}
	})

	$( "input[type=checkbox][name='service_3']" ).click(function(){
	    if($('#service_3').is(':checked')){$( ".divservice_3_description" ).show(); $(".erroraumoinscases_cochee").css({"visibility":"hidden"});}
	    if(!$('#service_3').is(':checked')){$( ".divservice_3_description" ).hide();}
	})


	servnomvalide = false;
	$("#nom_service").keyup(function(){
	    var obj = "#nom_service";
	    var cible = ".errornomservice";
	    servnomvalide = controlDataString(obj,cible);
	})

	//form submit
    $('#ActionEnregistrer').click(function(){

	//nom service
	var obj = "#nom_service";
	var cible = ".errornomservice";
	servnomvalide = controlDataString(obj,cible);

	//service_1 validé
	var choixService = false;
	if($('#service_1').is(':checked'))
	{   choixService = true;
	}
	//service_2 validé
	if($('#service_2').is(':checked'))
	{   choixService = true;
	}
	//service_3 validé
	if($('#service_3').is(':checked'))
	{   choixService = true;
	}

	 var is_submit = false;
	//si les données sont vérifiés et validés
	if(servnomvalide == true && choixService == true ){
	    is_submit = true;
	}

	if(is_submit){
	     $(".errordeclarationsmad").css({"visibility":"hidden"});
	     $('#ActionEnregistrer').submit();
	   return true;
	}else{
		if(choixService)
		     $(".erroraumoinscases_cochee").css({"visibility":"hidden"});
		else
		     $(".erroraumoinscases_cochee").css({"visibility":"visible"});

	    $(".errordeclarationsmad").css({"visibility":"visible"});
	    return false;
	}


    });

});