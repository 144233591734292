/**
 *  Agenda
 *  Use jQuery, Moment & Zabuto library
 */

;(function calendar($) {
    var CONTAINER = '.Agenda-calendar';
    var POPIN = '.Agenda-popin';
    var TEXT = '.Agenda-popinText';
    var BTN = '.Agenda-back';

    var datas = {
        '2018-02-11': [
            {
                'date': '2018-02-11',
                'badge': true,
                'title': 'Championnat de France',
                'body': '9h-18h',
                'footer': '2 rue tartampion de chicouli, Saint Paul',
                'target': '_self',
                'classname': 'classTest'
            },
            {
                'date': '2018-02-11',
                'badge': true,
                'title': 'Championnat de France',
                'body': '9h-18h',
                'footer': '2 rue tartampion de chicouli, Saint Paul',
                'target': '_self',
                'classname': 'classTest'
            },
            {
                'date': '2018-02-11',
                'badge': true,
                'title': 'Championnat de France',
                'body': '9h-18h',
                'footer': '2 rue tartampion de chicouli, Saint Paul',
                'target': '_self',
                'classname': 'classTest'
            },
            {
                'date': '2018-02-11',
                'badge': true,
                'title': 'Championnat de France',
                'body': '9h-18h',
                'footer': '2 rue tartampion de chicouli, Saint Paul Trois Châteaux',
                'target': '_self',
                'classname': 'classTest'
            },
        ],
        '2018-02-21': [
            {
                'date': '2018-02-21',
                'badge': true,
                'title': 'titre',
                'body': 'body',
                'footer': 'footer',
                'target': '_self',
                'classname': 'classTest'
            },
            {
                'date': '2018-02-21',
                'badge': true,
                'title': 'titre',
                'body': 'body',
                'footer': 'footer',
                'target': '_self',
                'classname': 'classTest'
            }
        ]
    };
    var CSA = CSA || {};
    var calendarData = [];
    var OPTIONS = {
        language: "fr",
        show_previous: true,
        show_next: true,
        today: true,
        nav_icon: {
            prev: '<svg class="icon icon--arrowPrev" width="16" height="27" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg"><path d="M16 13.5l-1.6-1.7L3.6 0 0 3.4l9.2 10L0 23.7 3.6 27l10.8-11.8 1.6-1.7" fill-rule="nonzero" fill="currentColor"/></svg>',
            next: '<svg class="icon icon--arrow" width="16" height="27" viewBox="0 0 16 27" xmlns="http://www.w3.org/2000/svg"><path d="M16 13.5l-1.6-1.7L3.6 0 0 3.4l9.2 10L0 23.7 3.6 27l10.8-11.8 1.6-1.7" fill-rule="nonzero" fill="currentColor"/></svg>'
        },

        ajax: {url: "#"},
        action_nav: function () {
            setTimeout(function () {
                todayClass()
            }, 0);
        }
    };

    var loader = function (callback, datas) {
        callback(datas);
    };

    $(document).ready(function () {
        if ($(CONTAINER).length > 0) {
            loader(init, datas);
        }
    });


    function init(datas) {
        // Create an array of events
        calendarData.length = 0;
        for (var date in datas) {
            Array.prototype.push.apply(calendarData, datas[date]);
        }

        OPTIONS['action'] = function (id) {
            var dateString = $(id.currentTarget).data('date');
            var data = datas[dateString];

            var events = cal.data('events');
            if (!data) {
                data = [];
            }
            for (var key in events) {
                var event = events[key];
                if (event.date === dateString) {
                    data.push(event)
                }
            }

            if (data && data.length > 0) {
                createPanel(data, dateString);
                openPanel();

                // Move badge-today on clicked day
                $(CONTAINER).find('.badge-today').removeClass('badge-today');
                date = new Date(dateString);
                $span = $("#" + this.id).find('span');
                if ($span.length > 0) {
                    $span.addClass('badge-today');
                } else {
                    $("#" + this.id).find('div').html('<span class="badge badge-today">' + date.getDate() + '</span>');
                }
            }

        };
        OPTIONS['ajax']['url'] = Routing.generate('csa_get_monthly_events');

        // Change month text
        changeText();

        // Declare the calendar
        var cal = $(CONTAINER).zabuto_calendar(OPTIONS);

        // Add a class on the current Day - library correction to overide style when there is event(s) on current day
        todayClass();
    }

    // Create the sliding panel content with the events of the day on click
    function createPanel(dateCollection, currentDate) {
        var popin = document.querySelector(TEXT);

        // Create Nodes for Date and add it, use the moment library to rewrite date format
        moment.locale('fr');
        var html = '<p class="Agenda-date">' + moment(currentDate, "YYYY-MM-DD").format('DD MMMM YYYY') + '</p><ul class="Agenda-list">';

        var priorityTab = [];
        var minPriority = 100;
        var maxPriority = 0;

        dateCollection.forEach(function (el) {
            var eventHtml = '<li class="Agenda-item">';
            eventHtml += '<p class="Agenda-hours"> <a href="' + el.url + '" target="' + el.target + '">' + el.title + '</a></p>';
            eventHtml += '<p class="Agenda-title">' + el.body + '</p>';
            eventHtml += '<p class="Agenda-place"><svg class="icon icon--spot" width="15" height="20" viewBox="0 0 15 20" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M10 15c-.5 1-1.8 2.5-2.6 2.5-1 0-2.7-2.5-2.7-2.5C2 15.3 0 16 0 17 0 18.7 3.3 20 7.4 20c4 0 7.4-1.2 7.4-2.7 0-1-2-1.7-4.8-2"/><path fill="currentColor" d="M7.4 3c1.7 0 3 1.3 3 3 0 1.8-1.3 3.2-3 3.2-1.8 0-3.2-1.4-3.2-3 0-2 1.4-3.3 3.2-3.3m-1 13c.5.7 1.4.7 1.7 0 0 0 4-5.5 4.7-7.2 1-1.8 1-3.7 0-5.5-1.5-3-5-4-8-2.7C1.6 2 .4 6 1.8 8.8 3.3 11.6 6.4 16 6.4 16"/></svg>' + el.footer + '</p>';
            eventHtml += '</li>';

            var priority = parseInt(el.priority);

            if (priority in priorityTab) {
                priorityTab[priority] += eventHtml;
            } else {
                priorityTab[priority] = eventHtml;
            }

            if (priority !== 0 && priority < minPriority) {
                minPriority = priority;
            }

            if (priority > maxPriority) {
                maxPriority = priority;
            }
        });

        //Order current day events by ascending priority. 0 being the default priority level, it's considered as the lowest priority level
        for (var i = minPriority; i <= maxPriority; i++) {
            if (i in priorityTab) {
                html += priorityTab[i];
            }
        }

        if (0 in priorityTab) {
            html += priorityTab[0];
        }

        html += '</ul>';
        popin.innerHTML = html;
    }

    // Open-close the sliding panel
    function openPanel() {
        var popin = $(POPIN);
        var btn = $(BTN);

        if (popin.attr('data-state') === 'close') {
            popin.attr('data-state', 'open');
        }

        btn.on('click', function () {
            popin.attr('data-state', 'close');
        });
    }

    // Change the core french traduction for month and day
    function changeText() {
        $.fn.zabuto_calendar_language = function (lang) {
            switch (lang.toLowerCase()) {
                case 'fr':
                    return {
                        month_labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
                        dow_labels: ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"]
                    };
                    break;
            }

        };
    }

    // Zabuto Calendar ibrary correction to add a class on current day and overide style when there is an event(s) on current day
    function todayClass() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        var todayID = '[id$="' + yyyy + '-' + mm + '-' + dd + '_day' + '"]';
        $(todayID).addClass('Agenda-today');
    }

}(jQuery));