/**
 * Simple accordion implementation.
 * We're just toggling the 'Accordion-item--open' class on items.
 */
;(function($) {

  var BUTTON_KEYS = [
    'Enter',
    'Spacebar',
    ' '
  ];

  var CLASSES = {
    group: 'Accordion',
    item: 'Accordion-item',
    item_active: 'Accordion-item--open',
    button: 'Accordion-title'
  };

  /**
   * Init
   */
  $(document).ready(function() {
    // On page load, scroll to anchor if any
    var items = $('.' + CLASSES.item);
    if (items.length > 0) {
      openAccordionOnLoad(items);
    }

    // Enable clicks and keyboard use
    var buttons = $('.' + CLASSES.button);
    buttons.on(
      'click keydown',
      // keydown can fire a few times when the user keeps pressing the key
      throttle(handleButtonEvent, 400, { leading: true, trailing: false })
    );
  });

  /**
   * Check that the user's input is a valid event for toggling the
   * accordion item's state
   * @param {Event} event
   */
  function handleButtonEvent(event) {
    if (
      (event.type === 'click') ||
      (event.type === 'keydown' && BUTTON_KEYS.indexOf(event.key) >= 0)
    ) {
      event.preventDefault();
      toggleAccordionItem(event.currentTarget);
    }
  }

  /**
   * Toggle an item's open/closed state
   * @param {HTMLElement} target
   * @param {Boolean} [shouldOpen]
   */
  function toggleAccordionItem(target, shouldOpen) {
    var item = $(target).first().closest('.' + CLASSES.item);
    if (item.length !== 1) {
      throw new Error('Invalid target accordion item');
    }

    // Next state can be forced with the 'open' argument,
    // otherwise we simply toggle the current state
    if (typeof shouldOpen !== 'boolean') {
      shouldOpen = item.hasClass(CLASSES.item_active) === false;
    }

    // Open this item, close other open items (if any)
    if (shouldOpen) {
      item.addClass(CLASSES.item_active);
      item.siblings('.' + CLASSES.item_active).removeClass(CLASSES.item_active);
      // Log opened item
      var route = item.attr('data-log-route');
      var contentId = item.attr('data-content-id');
      if (route && contentId) {
        var url = Routing.generate(route, { contentId: contentId });
        $.ajax({ url: url }).done(function(response){});
      }
    }
    else {
      // Close this item
      item.removeClass(CLASSES.item_active);
    }
  }

  /**
   * On page load, if the current URL hash matches an accordion item,
   * reveal that item and scroll to it
   */
  function openAccordionOnLoad(items) {
    var target = null;
    var hash = window.location.hash || '';
    var alias = decodeURIComponent(hash.slice(1)).replace('"', '');

    // Try to find our target element
    items.each(function(index, element) {
      if (element.getAttribute('data-alias') === alias) {
        target = element;
      }
    });

    if (target) {
      // Reveal content
      toggleAccordionItem(target, true);
      // Scroll and leave room for sticky header
      CSA.scroll.toElement(target, true);
    }
  }

}(jQuery));
