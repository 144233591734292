/**
 * Wrap legacy <table class="listing"> in a container with the resp-table class
 * Note that there is already a more complex responsive-tables.js script that modifies
 * the DOM significantly, for <table class="responsive">
 */
(function($) {
    if (typeof $ !== 'function') return;
    $('table.listing').each(function(index, element) {
        var table = $(element);
        // don't double wrap
        var wrapper = table.closest('.resp-table');
        if (wrapper.length > 0) return;
        table.wrap('<div class="resp-table"></div>');
    });
}(jQuery));
