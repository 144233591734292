jQuery(document).ready(function($) {
	//personne moral
	$('.t_mpersonne').click(function(){
	    $( ".divPersonnePhysique" ).hide();
	    $(".erroridentification").css({"visibility":"hidden"});
	    $( ".divPersonneMorale" ).show();

	    $("span#errords").css({"visibility":"hidden"});
	    $(".btn-envoi").css({"visibility":"visible"});
	});

	//personne physique
	$('.t_ppersonne').click(function(){
	    $( ".divPersonneMorale" ).hide();
	    $(".erroridentification").css({"visibility":"hidden"});
	    $( ".divPersonnePhysique" ).show();

	    $("span#errords").css({"visibility":"hidden"});
	    $(".btn-envoi").css({"visibility":"visible"});

	});

	//form juridique société
	$('.fj_societe').click(function(){
	    $( ".divfjassociation" ).hide();
	    $( ".divfjautre" ).hide();
	    $(".erroridentification").css({"visibility":"hidden"});
	    $( ".divfjsociete" ).show();
	});

	//form juridique association
	$('.fj_association').click(function(){
	    $( ".divfjautre" ).hide();
	    $( ".divfjsociete" ).hide();
	    $(".erroridentification").css({"visibility":"hidden"});
	    $( ".divfjassociation" ).show();
	});

	//form juridique autre
	$('.fj_autre').click(function(){
	    $( ".divfjassociation" ).hide();
	    $( ".divfjsociete" ).hide();
	    $(".erroridentification").css({"visibility":"hidden"});
	    $( ".divfjautre" ).show();
	});

	denomvalide = false;
	$("#denomination").keyup(function(){
	    var obj = "#denomination";
	    var cible = ".errordenomination";
	    denomvalide = controlDataString(obj,cible);
	});

	//veriffication date société
	 dateValideSoc = false;
	$("#im_date_day").keyup(function(){
		var cible = ".errordate";
		var im_date_day = $(this).val();
		im_date_day = $.trim(im_date_day);
		var im_date_month = $("#im_date_month").val();
		im_date_month = $.trim(im_date_month);
		var im_date_year = $("#im_date_year").val();
		im_date_year = $.trim(im_date_year);
		dateValideSoc= verifiedate(im_date_day,im_date_month,im_date_year,cible);
	});

	$("#im_date_month").keyup(function(){
		var cible = ".errordate";
		var im_date_month = $(this).val();
		im_date_month = $.trim(im_date_month);
		var im_date_day = $("#im_date_day").val();
		im_date_day = $.trim(im_date_day);
		var im_date_year = $("#im_date_year").val();
		im_date_year = $.trim(im_date_year);
		dateValideSoc= verifiedate(im_date_day,im_date_month,im_date_year,cible);
	});

	$("#im_date_year").keyup(function(){
		var cible = ".errordate";
		var im_date_year = $(this).val();
		im_date_year = $.trim(im_date_year);
		var im_date_day = $("#im_date_day").val();
		im_date_day = $.trim(im_date_day);
		var im_date_month = $("#im_date_month").val();
		im_date_month = $.trim(im_date_month);
		dateValideSoc = verifiedate(im_date_day,im_date_month,im_date_year,cible);
	});

	SocImLieuValide = false;
	$("#societe_immatriculee_lieu").keyup(function(){
		var obj = "#societe_immatriculee_lieu";
		var cible = ".errorsocimmlieu";
		SocImLieuValide = controlDataString(obj,cible);
	});

	SocImNumValide = false;
        $("#societe_immatriculee_numero").keyup(function(){
	    var reg_numero = /^[0-9]+$/;
	    var obj = "#societe_immatriculee_numero";
	    var cible = ".errorsocimmnum";
	    SocImNumValide = controlDataString(obj,cible,reg_numero);
    });


     //veriffication date association
     dateValideAss = false;
    $("#ass_date_day").keyup(function(){

	    var cible = ".errordateass";
	    var im_date_day = $(this).val();
	    im_date_day = $.trim(im_date_day);

	    var im_date_month = $("#ass_date_month").val();
	    im_date_month = $.trim(im_date_month);

	    var im_date_year = $("#ass_date_year").val();
	    im_date_year = $.trim(im_date_year);

	    dateValideAss= verifiedate(im_date_day,im_date_month,im_date_year,cible);
    });

    $("#ass_date_month").keyup(function(){

	    var cible = ".errordateass";
	    var im_date_month = $(this).val();
	    im_date_month = $.trim(im_date_month);

	    var im_date_day = $("#ass_date_day").val();
	    im_date_day = $.trim(im_date_day);

	    var im_date_year = $("#ass_date_year").val();
	    im_date_year = $.trim(im_date_year);

	    dateValideAss= verifiedate(im_date_day,im_date_month,im_date_year,cible);

    });

    $("#ass_date_year").keyup(function(){

	    var cible = ".errordateass";
	    var im_date_year = $(this).val();
	    im_date_year = $.trim(im_date_year);

	    var im_date_day = $("#ass_date_day").val();
	    im_date_day = $.trim(im_date_day);

	    var im_date_month = $("#ass_date_month").val();
	    im_date_month = $.trim(im_date_month);

	    dateValideAss = verifiedate(im_date_day,im_date_month,im_date_year,cible);

    });


     $("#ass_declaration_lieu").keyup(function(){
	    var obj = "#ass_declaration_lieu";
	    var cible = ".errorassdeclieu";
	    assDecLieuValide = controlDataString(obj,cible);

    });

	SiegAssValide = false;
	$("#ass_siege_social").keyup(function(){
	    var obj = "#ass_siege_social";
	    var cible = ".errorsiegesoc";
	    SiegAssValide = controlDataString(obj,cible);
    });

    contNomValide = false;
    $("#contact_nom").keyup(function(){
	    var obj = "#contact_nom";
	    var cible = ".errorcontactnom";
	    contNomValide = controlDataString(obj,cible);
    });

    contAdresValid = false;
    $("#contact_adresse").keyup(function(){
	    var obj = "#contact_adresse";
	    var cible = ".errorcontactadresse";
	    contAdresValid = controlDataString(obj,cible);
    });


    $("#contact_telephone").keyup(function(){
	    var reg_numero = /^0[1-9][0-9]{8}$/;
	    var obj = "#contact_telephone";
	    var cible = ".errorcontacttelephone";
	    conttelephValide = controlDataString(obj,cible,reg_numero);
    });

    contMelValide = false;
    $("#contact_mel").keyup(function(){
	    var reg_numero = /[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}/;
	    var obj = "#contact_mel";
	    var cible = ".errorcontactmel";
	    contMelValide = controlDataString(obj,cible,reg_numero);
    });

    directeurValide = false;
    $("#directeur").keyup(function(){
	    var obj = "#directeur";
	    var cible = ".errordirecteur";
	    directeurValide = controlDataString(obj,cible);
    });

	fjautreValide = false;
     $("#autre_description").keyup(function(){
	    var obj = "#autre_description";
	    var cible = ".errordescrpfjautre";
	    fjautreValide = controlDataString(obj,cible);
    });


    /************* Personne physique ***************/
    nomValide = false;
    $("#nom").keyup(function(){
	    var obj = "#nom";
	    var cible = ".errornom";
	    nomValide = controlDataString(obj,cible);
    });

    adresseValide = false;
    $("#adresse").keyup(function(){
	    var obj = "#adresse";
	    var cible = ".erroradresse";
	    adresseValide = controlDataString(obj,cible);
    });

    melvalide = false;
    $("#mel").keyup(function(){
	    var reg_numero = /[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}/;
	    var obj = "#mel";
	    var cible = ".errormel";
	    melvalide = controlDataString(obj,cible,reg_numero);
    });

    $("#telephone").keyup(function(){
	    var reg_numero = /^0[1-9][0-9]{8}$/;
	    var obj = "#telephone";
	    var cible = ".errortelephone";
	    telephValide = controlDataString(obj,cible,reg_numero);
    });

    //form submit
    $('#Actionsuivant').click(function(){

	//denomination
	var obj = "#denomination";
	var cible = ".errordenomination";
	denomvalide = controlDataString(obj,cible);

	//date valide societe
	cible = ".errordate";
	var im_date_day = $("#im_date_day").val();
	im_date_day = $.trim(im_date_day);
	var im_date_month = $("#im_date_month").val();
	im_date_month = $.trim(im_date_month);
	var im_date_year = $("#im_date_year").val();
	im_date_year = $.trim(im_date_year);
	dateValideSoc= verifiedate(im_date_day,im_date_month,im_date_year,cible);

	obj = "#societe_immatriculee_lieu";
	cible = ".errorsocimmlieu";
	SocImLieuValide = controlDataString(obj,cible);

	var reg_numero = /^[0-9]+$/;
	obj = "#societe_immatriculee_numero";
	cible = ".errorsocimmnum";
	SocImNumValide = controlDataString(obj,cible,reg_numero);

	cible = ".errordateass";
	var ass_date_day = $("#ass_date_day").val();
	ass_date_day = $.trim(ass_date_day);
	var ass_date_month = $("#ass_date_month").val();
	ass_date_month = $.trim(ass_date_month);
	var ass_date_year = $("#ass_date_year").val();
	ass_date_year = $.trim(ass_date_year);
	dateValideAss= verifiedate(ass_date_day,ass_date_month,ass_date_year,cible);

	obj = "#ass_siege_social";
	cible = ".errorsiegesoc";
	SiegAssValide = controlDataString(obj,cible);

	obj = "#contact_nom";
	cible = ".errorcontactnom";
	contNomValide = controlDataString(obj,cible);

	obj = "#contact_adresse";
	cible = ".errorcontactadresse";
	contAdresValid = controlDataString(obj,cible);

	reg_numero = /[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}/;
	obj = "#contact_mel";
	cible = ".errorcontactmel";
	contMelValide = controlDataString(obj,cible,reg_numero);

	obj = "#directeur";
	cible = ".errordirecteur";
	directeurValide = controlDataString(obj,cible);

	obj = "#autre_description";
	cible = ".errordescrpfjautre";
	fjautreValide = controlDataString(obj,cible);

	obj = "#nom";
	cible = ".errornom";
	nomValide = controlDataString(obj,cible);

	obj = "#adresse";
	cible = ".erroradresse";
	adresseValide = controlDataString(obj,cible);

	reg_numero = /[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}/;
	obj = "#mel";
	cible = ".errormel";
	melvalide = controlDataString(obj,cible,reg_numero);

	obj = "#ass_declaration_lieu";
	cible = ".errorassdeclieu";
	assDecLieuValide = controlDataString(obj,cible);

	var personne = $('input[type=radio][name="t_personne"]:checked').attr('value');
	//1 : personne morale
	//2 : personne physique
	//les formes juridique :
	//1 :societe
	//2: association
	//3: autre

	var is_submit = false;
	if(personne == '1'){
	    if(denomvalide == true && contNomValide == true && contAdresValid == true && contMelValide == true &&  directeurValide == true){
			var form_juridique = $('input[type=radio][name="form_juridique"]:checked').attr('value');
			if(form_juridique == '1' && dateValideSoc == true && SocImLieuValide == true && SocImNumValide == true){
				is_submit = true;
			}
			if(form_juridique == '2' && dateValideAss == true && SiegAssValide == true && assDecLieuValide == true){
				is_submit = true;
			}
			if(form_juridique == '3' && fjautreValide == true) {
				is_submit  =  true;
			}

		} else {
		 	is_submit = false;
	    }

	} else if(personne == '2'){
		    if(nomValide == true && adresseValide == true && melvalide == true){
			 	is_submit = true;
			}

		}

		//si les données sont vérifiés et validés
		if(is_submit){
		    $(".erroridentification").css({"visibility":"hidden"});
		    $('#Actionsuivant').submit();
		   	return true;
		} else {
		    $(".erroridentification").css({"visibility":"visible"});
		    return false;
		}
    });

});

/*!
    Invert the status of checkboxes named 'checkboxname' in form 'formname'.
    If you have a list of checkboxes name them with 'someName[]' in order to toggle them all.
*/
function ezjs_toggleCheckboxes( formname, checkboxname )
{
    with( formname )
        {
        for( var i = 0, l = elements.length; i < l; i++ )
        {
            if( elements[i].type === 'checkbox' && elements[i].name == checkboxname && elements[i].disabled == false )
            {
                if( elements[i].checked == true )
                {
                    elements[i].checked = false;
                }
                else
                {
                    elements[i].checked = true;
                }
            }
        }
    }
}

function verifiedate(day,month,year,cible){

     var reg_date_daymonth = /[0-9]{2}/;
     var reg_date_year = /[0-9]{4}/;

     dayValide=false;
     monthValide = false;
     yearValide = false;

    if(day.length ==2 && day >= 1 && day <= 31){
	$(cible).css({"visibility":"hidden"});
	  if(!day.match(reg_date_daymonth)){
		$(cible).css({"visibility":"visible"});
	    }else{
		dayValide = true;
	    }
    }else{
	$(cible).css({"visibility":"visible"});
    }

    if(dayValide){
	if(month.length == 2 && month >= 1 && month <= 12){
	    $(cible).css({"visibility":"hidden"});
	      if(!month.match(reg_date_daymonth)){
		    $(cible).css({"visibility":"visible"});
		}else{
		    monthValide = true;
		}
	}else{
	    $(cible).css({"visibility":"visible"});
	}
    }
    if(monthValide){
	if(year.length == 4 && year >= 1){
		$(cible).css({"visibility":"hidden"});
		  if(!year.match(reg_date_year)){
			$(cible).css({"visibility":"visible"});
		    }else{
			yearValide = true;
		    }
	    }else{
		    $(cible).css({"visibility":"visible"});
		}
	}
	if(dayValide && monthValide && yearValide){
	    return true;
	}else{
	    return false;
	}
}

function controlDataString(obj,cible,regExp){
    valide = false;
    var datastring = $(obj).val();
       datastring = $.trim(datastring);
       if(!regExp){
	    if(datastring.length!=0){
		 $(cible).css({"visibility":"hidden"});
		 valide = true;
	    }else{
		$(cible).css({"visibility":"visible"});
		valide = false;
	    }
       }else{
       if(datastring.length!=0){
		$(cible).css({"visibility":"hidden"});
		valide = true;
		  if(!datastring.match(regExp)){
		    $(cible).css({"visibility":"visible"});
			valide = false;
		  }
	    }else{
		$(cible).css({"visibility":"visible"});
		valide = false;
	    }

       }

       return valide;
}