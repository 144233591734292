/**
 *  Summary - Create items with anchor and open/*close comportment
 *  Use jQuery
 */

;(function summary($) {
  $(document).ready(init);

  var STICKY_OFFSET = -200;
  var CONTAINER_SEL = '.Summary';
  var TITLES_SEL = '.Wysiwyg > h2';
  var LIST_SEL = '.Summary-subList';

  var container;
  var isDesktop = matchMedia(CSA.mq.desktop);

  function init() {
    container = $(CONTAINER_SEL).first();
    if (container.length === 1) {
      // Create the summary items and the listen on item to close the popin and scroll
      addPageLinks();
      // Stickify the summary on desktop
      if (isDesktop.matches) {
        var update = throttle(updateStickyState, 200, {leading: false, trailing: true});
        $(window).on('scroll', update);
        update();
      }
      // Add the smoothscroll on anchor
      container.on('click', 'a[href*="#"]', smoothScroll);
    }
  }

  /**
   * Update the sticky state, and if need be the height of the placeholder element
   */
  function updateStickyState() {
    if (isDesktop.matches === false) return;
    var isSticky = container.attr('data-sticky') === 'true';
    // height can be the previous value with forced in JS (if currently sticky),
    // or it can be the actual auto height of the Summary container.
    var height = Math.ceil(container.height());
    // Change state when the top of the viewport reaches the bottom of Summary,
    // minus a small offset to account for the sticky header.
    var scrollLimit = container.offset().top + height + STICKY_OFFSET;
    var scrollTop = Math.floor($(window).scrollTop());

    if (!isSticky && scrollTop > scrollLimit) {
      container.css('height', height + 'px').attr('data-sticky', 'true');
    } else if (isSticky && scrollTop < scrollLimit) {
      container.attr('data-sticky', 'false');
    }
  }

  /**
   * Add links to titles found in the current page
   */
  function addPageLinks() {
    var titles = [];
    $(TITLES_SEL).each(function (index, element) {
      // Add an id to the section
      var id = 't' + (index + 1);
      var title = $(this).attr('id', id).text().trim();
      // Store the data so we can create links
      titles.push({
        title: title,
        id: id
      });
    });

    // Create the items with the arrays and add a href link to the anchor
    var target = container.find(LIST_SEL).first();
    var html = titles.map(function(item) {
      var link = '<a href="#' + item.id + '">' + item.title + '</a>';
      return '<li class="Summary-subItem">' + link + '</li>';
    }).join('\n');
    target.append(html);
  }

  /**
   * Handle clicks on summary links, scrolling smoothly to the element
   * it linked to if it's in the same page
   * @param event
   */
  function smoothScroll(event) {
    var targetEl;
    var targetId;
    var link = event.currentTarget;

    // Extract the id from the URL
    var href = (link.getAttribute('href') || '').trim();
    if (href.indexOf('#') === 0 && href.length > 1) {
      targetId = href.replace('#', '');
    } else if (
      location.hostname === link.hostname
      && location.pathname.replace(/^\//, '') === link.pathname.replace(/^\//, '')
    ) {
      targetId = href.split('#')[1];
    }
    if (!targetId) return;

    // Try to identify the corresponding element in the page
    try {
      targetEl = document.getElementById(targetId) || document.querySelector('[name="' + targetId + '"]');
    } catch(error) {}
    if (!targetEl) return;

    // Still there? Let's do the scrolling ourselves
    event.preventDefault();
    CSA.scroll.toElement(targetEl);

    // On mobile, close the summary modal
    if (isDesktop.matches === false) {
      window.CSA.state.toggle(container, false);
    }
  }
}(jQuery));
