function closeChilds(element) {
	var idElement = element.attr('id').slice(4);
	if(element.parent().next('li').hasClass('child-of-parent'+idElement)) {
		closeChilds(element.parent().next('li').find('div'));
	}
	if(element.hasClass('open')) {
		element.removeClass('open');
		if($("#selectedmenu").val() == "") {
			$("#lien-item"+idElement).removeClass("on");
		}
		$('.child-of-parent'+idElement).slideToggle();
	}
}

jQuery(document).ready(function($) {
	$('#operateur-annuaire-list-sommaire.list-sommaire .item-sommaire').click(function() {
		var id = $(this).attr('id').slice(4);

		if($(this).hasClass('open')) {
			closeChilds($(this));
		} else {
			$(".child-of-parent"+id).slideToggle();
			$("#lien-item"+id).addClass("on");
			$(this).addClass("open");
		}
	});
});