;(function playerVideo($) {
    $(function() {
        $('.playerVideo').each(initVideoPlayer);
    });

    function initVideoPlayer(index, node) {
        node.id = 'jwplayer-' + index;
        var config = {
            'playlist': {'sources': fetchSource(node)},
            'width': node.getAttribute('data-width'),
            'height': node.getAttribute('data-height'),
            'sharing': {'sites': ['facebook', 'twitter', 'linkedin', 'email'], 'heading': 'Partager cette vidéo'}
        };

        if (node.getAttribute('data-image').length > 0) {
            config.playlist.image = node.getAttribute('data-image');
        }

        if (node.getAttribute('data-autostart')) {
            config.autostart = 'viewable';
        }

        jwplayer(node.id).setup(config);
    }

    function fetchSource(node) {
        var source = node.getAttribute('data-source');
        var sources = [];

        if(source.toLowerCase().indexOf('http') !== -1){
            source = source.replace('http', 'https');
        }

        sources.push({'file': source});
        if (source.indexOf('jwplayer.smil') !== -1) {
            sources.push(
                {'file': source.replace('jwplayer.smil', 'playlist.m3u8')}
            );
        } else {
            sources.push(
                {'file': source.replace('rtmp', 'https') + '/playlist.m3u8'}
            );
        }

        return sources;
    }
}(jQuery));