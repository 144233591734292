;(function share($) {
  $(document).ready(init);

  function init() {
    $('.Share .Share-item--social .Share-subItem').each(function () {
      var href = $(this).attr('href');
      href = href.replace('%url%', appUrl);
      href = href.replace('%metaTitle%', metaTitle);
      $(this).attr('href', href);
    });
    var hrefMailTo = $('.Share .mailTo').attr('href');
    if (hrefMailTo) {
      hrefMailTo = hrefMailTo.replace('%url%', appUrl);
      hrefMailTo = hrefMailTo.replace('%metaTitle%', metaTitle);
      $('.Share .mailTo').attr('href', hrefMailTo);
    }
  }
}(jQuery));