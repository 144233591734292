/**
 * Change State Widget
 * Change the state of an element (active or not) and all the others state
 */
;(function state($) {

  $(document).ready(init);

  var ATTR = 'data-state';
  var CONTAINER_CLASS = 'js-State';
  var BTN_CLASS = 'js-State-btn';
  var MODAL_CLASS = 'js-State--modal';
  var MODAL_MOBILE_CLASS = 'js-State--modalMobile';
  var CLOSE_OUTSIDE_CLASS = 'js-State--dismiss';
  var NO_SCROLL_CLASS = 'noScroll';
  var NO_SCROLL_MOBILE_CLASS = 'noScrollMobile';
  var isDesktop = matchMedia(CSA.mq.desktop);

  // Make the toggleState method public
  window.CSA.state = {
    toggle: toggleState
  };

  function init() {
    // Set inactive state for everything
    $('.'+CONTAINER_CLASS).attr(ATTR, 'inactive');

    // Watch click on js-State class
    var body = $('body');
    body.on('click', '.'+BTN_CLASS, handleButtonClick);

    // Change the state on click outside the element (only for some js-State containers)
    if ($('.'+CLOSE_OUTSIDE_CLASS).length > 0) {
      body.on('click', closeIfOutside);
    }
  }

  /**
   * Close some containers when the user clicks outside the container
   */
  function closeIfOutside(event) {
    var openContainerSel = '.' + CLOSE_OUTSIDE_CLASS + '[data-state="active"]';
    var openContainers = $(openContainerSel);
    if (openContainers.length > 0) {
      if ($(event.target).closest(openContainerSel).length === 0) {
        toggleState(openContainers, false);
      }
    }
  }

  /**
   * Toggle a 'js-State' container between data-state="active|inactive",
   * and optionally block page scrolling
   * @param {Event} event
   */
  function handleButtonClick(event) {
    event.stopPropagation();

    var container = $(event.currentTarget)
      .parents('.'+CONTAINER_CLASS)
      .first();
    var allContainers = $('.'+CONTAINER_CLASS);

    if (container.attr(ATTR) !== 'active') {
      // only one 'js-State' container can be active at the same time
      allContainers.attr(ATTR, 'inactive');
      container.attr(ATTR, 'active');
      // conditionally lock body scrolling
      lockScroll(container);
    } else {
      container.attr(ATTR, 'inactive');
      // conditionally unlock body scrolling
      unlockScroll(container);
    }
  }

  /**
   * Set a 'state' container as active or inactive,
   * and optionally lock page scrolling
   * @param {Element|jQuery} element
   * @param {boolean|undefined} activate
   */
  function toggleState(element, activate) {
    var container = $(element);
    if (container.length > 0 && container.hasClass(CONTAINER_CLASS) === false) {
      throw new Error('Trying to toggle state of an element which is not a state container');
    }
    if (typeof activate !== 'boolean') {
      activate = $(container).attr(ATTR) === 'inactive';
    }

    if (activate) {
      // on mobile, only one 'js-State' container can be active at the same time
      if (isDesktop.matches === false) {
        $('.' + CONTAINER_CLASS).attr(ATTR, 'inactive');
      }
      container.attr(ATTR, 'active');
      // conditionally lock page scrolling
      lockScroll(container);
    } else {
      container.attr(ATTR, 'inactive');
      // conditionally unlock page scrolling
      unlockScroll(container);
    }
  }

  function lockScroll(container) {
    if (container.hasClass(MODAL_CLASS)) {
      $('body').addClass(NO_SCROLL_CLASS)
        .removeClass(NO_SCROLL_MOBILE_CLASS);
    } else if (container.hasClass(MODAL_MOBILE_CLASS)) {
      $('body').addClass(NO_SCROLL_MOBILE_CLASS)
        .removeClass(NO_SCROLL_CLASS);
    }
  }

  function unlockScroll(container) {
    if (container.hasClass(MODAL_CLASS) || container.hasClass(MODAL_MOBILE_CLASS)) {
      $('body').removeClass(NO_SCROLL_CLASS)
        .removeClass(NO_SCROLL_MOBILE_CLASS);
    }
  }

})(jQuery);
