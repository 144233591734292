;(function() {

  var MIN_SCROLL_DURATION = 250;
  var MAX_SCROLL_DURATION = 750;
  var isDesktop = matchMedia(CSA.mq.desktop);

  CSA.scroll = {
    toElement: scrollToElement
  };

  /**
   * Animated scrolling to an element in the page,
   * with some space reserved for the fixed header
   * @param {Element|jQuery} element 
   * @param {boolean} [avoidHeader]
   */
  function scrollToElement(element, avoidHeader) {
    var root = jQuery(document.documentElement);
    var target = jQuery(element).first();
    if (target.length === 0) return;
    if (avoidHeader == null) avoidHeader = true;

    // Figure out current scroll and target position
    var currentScroll = Math.floor(root.scrollTop());
    var scrollToValue = Math.floor(target.offset().top);
    if (avoidHeader) {
      var headerSize = isDesktop.matches ? 110 : 90;
      scrollToValue = Math.max(0, scrollToValue - headerSize);
    }

    // Speed = 0.25ms per pixel (e.g. 500ms for 2000px of travel)
    var speed = clamp(
      Math.round(Math.abs(currentScroll - scrollToValue) * 0.25),
      MIN_SCROLL_DURATION,
      MAX_SCROLL_DURATION
    );

    root.animate({ scrollTop: scrollToValue }, speed);
  }

  /**
   * Constrain a number between a lower a higher bound
   * @param {number} boundedValue
   * @param {number} minValue
   * @param {number} maxValue
   */
  function clamp(boundedValue, minValue, maxValue) {
    var low = Math.min(minValue, maxValue);
    var high = Math.max(minValue, maxValue);
    return Math.max(low, Math.min(high, boundedValue));
  }

}());
