/**
 *  Agenda
 *  Use jQuery, Moment & Zabuto library
 */

;(function($) {
    var INPUT_SEARCH_HEAD = 'input#query_query_head',   // form de la popin
        INPUT_SEARCH_PAGE = 'input#query_query';        // form de la page de recherche

    // options globales (cf: http://api.jqueryui.com/autocomplete/)
    var autoCompleteOptions = {
        minLength: 3,
        delay: 200,
        select: function( event, ui ) {
            if (ui.item !== undefined && ui.item !== null) {
                window.location.assign(ui.item.url);
            }
        }
    };

    // retourne un callback pour l'ajax/réponse (change en fonction de l'input utilisé)
    var getSourceCallbackForElement = function(elm) {
        return function(request, response) {
            var formElm = $(elm).parents('form'),
                url = $(formElm).attr('action'),
                data = $(formElm).serializeArray();

            $.ajax({
                url: url,
                method: 'get',
                dataType: "json",
                data: data,
                success: function(data) {
                    response(data);
                }
            });
        };
    };

    /* initialisation */
    $(document).ready(function () {
        var headOpts = autoCompleteOptions;

        // input de la popin
        headOpts.source = getSourceCallbackForElement(INPUT_SEARCH_HEAD);
        $(INPUT_SEARCH_HEAD).autocomplete(headOpts);

        // input de la page (pas toujours présent)
        var el = $(INPUT_SEARCH_PAGE);
        if (el.length) {
            headOpts.source = getSourceCallbackForElement(INPUT_SEARCH_PAGE);
            $(INPUT_SEARCH_PAGE).autocomplete(autoCompleteOptions);
        }
    });
}(jQuery));