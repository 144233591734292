/**
 * Sticky Header (desktop-only)
 */
;(function($) {
  $(document).ready(init);

  var VERTICAL_LIMIT = 150;
  var isDesktop = matchMedia(CSA.mq.desktop);
  var header;

  function init() {
    var target = $('.Header');
    if (target.length !== 0 && isDesktop.matches) {
      header = target;
      $(window).on('scroll', throttle(
        updateSticky,
        200,
        {leading: false, trailing: true}
      ));
    }
  }

  function updateSticky() {
    if (isDesktop.matches === false) return;

    // State should be the string 'true' or 'false'
    var currentState = header.attr('data-sticky');
    var nextState = String($(window).scrollTop() >= VERTICAL_LIMIT);

    if (nextState !== currentState) {
      header.attr('data-sticky', nextState);
    }
  }
}(jQuery));