;(function faq($) {

  var VOTE_BLOCK_SEL = '.FAQ-vote';
  var VOTE_BUTTON_SEL = '.FAQ-voteButton';
  var VOTE_MESSAGE_SEL = '.FAQ-voteMessage';
  var containers;
  var userIdProvider;

  /**
   * Init
   */
  $(document).ready(function() {
    containers = $(VOTE_BLOCK_SEL);
    if (containers.length === 0) return;

    // Create fingerprint (async)
    userIdProvider = $.Deferred();
    new Fingerprint2().get(function(result) {
      userIdProvider.resolve(result);
    });

    // Get the user's previous choices (if any)
    initFaqVote();

    // Log the user's vote on a specific choice
    $(VOTE_BUTTON_SEL).on('click', onVoteHandler);
  });

  function initFaqVote() {
    var contentIds = [];
    containers.map(function(index, element) {
      var id = element.getAttribute('data-content-id');
      if (id) contentIds.push(id);
    });
    userIdProvider.done(function(userId) {
      getUserVotes(userId, contentIds);
    })
  }

  /**
   * Get the user's previous votes (if any) and update the DOM accordingly
   * @param {string} userId
   * @param {Array} contentIds
   */
  function getUserVotes(userId, contentIds) {
    $.ajax({
      url: Routing.generate('csa_faq_init_vote', {userId: userId}),
      data: { contentIds: contentIds }
    })
    .done(function(userVotes) {
      for (var contentId in userVotes) {
        toggleVoteButtons(contentId, userVotes[contentId]);
      }
    })
  }

  /**
   * Send the user's vote to the backend, and update the UI
   */
  function onVoteHandler(event) {
    event.preventDefault();
    var button = $(event.currentTarget);
    var parent = button.closest('[data-content-id]');
    var value = String(button.attr('data-value')).trim();
    var contentId = parent.attr('data-content-id');

    // Update the UI immediately
    toggleVoteButtons(contentId, value);

    // Send a request to update the database
    var dfd = $.Deferred();
    new Fingerprint2().get(function(fingerprint) {
      dfd.resolve(fingerprint);
    });
    dfd.done(function(fingerprint){
      $.ajax({
        url: Routing.generate('csa_faq_save_vote', {
          userId: fingerprint,
          contentId: contentId,
          value: value
        })
      }).done(function(){
        parent.find(VOTE_MESSAGE_SEL)
          .fadeIn()
          .delay(3000)
          .fadeOut();
      });
    });
  }

  /**
   * For a given contentId and value (0 or 1), set the corresponding vote buttons
   * to pressed or unpressed.
   */
  function toggleVoteButtons(contentId, value) {
    var container = $(VOTE_BLOCK_SEL + '[data-content-id="' + contentId + '"]');
    container.find(VOTE_BUTTON_SEL).each(function(index, element) {
      var pressed = String(element.getAttribute('data-value')).trim() === String(value).trim();
      element.setAttribute('aria-pressed', pressed ? 'true' : 'false');
    });
  }

}(jQuery));
