;(function brieve($) {
  $(document).ready(init);

  function init() {

    var $enBref = $('.EnBref');
    $enBref.on('mouseover', function(){
      var href = $(this).attr('href');
      if (href === '#') {
        $(this).css('text-decoration', 'none');
        $(this).css('cursor', 'default');
      }
    });

    $enBref.on('click', function(e){
      var href = $(this).attr('href');
      if (href === '#') {
        e.preventDefault();
      }
    });
  }

}(jQuery));