/**
 *  Old Modules
 *  Use jQuery
 */

;(function modules($) {
  $(document).ready(init);

  var ICONBTN = '<svg class="Btn-border" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 262 62"><path fill="none" d="M43.3 1h-42v60H194" vector-effect="non-scaling-stroke"></path><path fill="none" d="M225.3 61H261V1H76.8" vector-effect="non-scaling-stroke"></path></svg>';

  function init() {
    // Custom BTN
    $(".bouton_recherche").html( ICONBTN + 'Envoyer' );

    // Change input submit into button
    $("input[name='ActionCollectInformation']").parent().html( '<button name="ActionCollectInformation" class="Btn Btn--lightBlue" type="submit">'+ICONBTN+'Envoyer</button>' );
    $("input[name='Actionenregistrer']").parent().html( '<button name="Actionenregistrer" class="Btn Btn--lightBlue" type="submit">'+ICONBTN+'Enregistrer</button>' );
    $("input#send").wrap( '<span class="Btn--inner"></span>' ).parent().html( '<button class="Btn Btn--lightBlue" type="submit">'+ICONBTN+'Rechercher</button>' );

    // Add class for Pannel
    $(".cadre_pointille").wrapInner( '<div class="Pannel-content"></div>' );
    $(".contenu_textecontourorange").wrapInner( '<div class="Pannel-content"></div>' );
    $(".cartouche_chaines").wrapInner( '<div class="Pannel"></div>' );
    $("#matnt .contenu_fondgris table td").wrapInner( '<div class="Pannel-content"></div>' );


    // Custom input Radio
    $(".field_formoption label input[type='radio']").after('<span class="checkmark"></span>');
    $(".field_formoption_large label input[type='radio']").after('<span class="checkmark"></span>');
    $(".field_collection label input[type='radio']").after('<span class="checkmark"></span>');

    // Vertical align top
    $(".label_form:has('~ .field_form textarea')").addClass( "valignTop" );
    $(".label_form:has('~ .field_formoption')").addClass( "valignTop" );
    $(".label_form:has('~ .field_formoption_large')").addClass( "valignTop" );

    // custom select
  	$( "select" ).selectmenu({
      position: { my : "left top", at: "left bottom-50", collision: "none" }
    });
  	$( "select#form_type_personne" ).selectmenu({
      position: { my : "left top", at: "left bottom-50", collision: "none" },
      change: function( event, data ) { personneSelect() }
    });
    $( "#form_annee select" ).selectmenu({
      position: { my : "left top", at: "left bottom-50", collision: "none" },
      change: function( event, data ) { $('#form_annee').submit() }
    });

    /* Clear Input */
  	$('input[type="text"]').not('.not-clear').focus(function() {
  	    if (this.value == this.defaultValue){
  	        this.value = '';
  	    }
  	    if(this.value != this.defaultValue){
  	        this.select();
  	    }
  	});

  }

}(jQuery));